@import '../../../../styles/config.scss';

.image {
  max-height: calc(100vh - 96px - 24px - 39px);
  max-width: 100%;
  height: auto;
  width: auto !important;
}

.fieldsContainer {
  max-height: calc(100vh - 300px);
  overflow-y: scroll;
}
