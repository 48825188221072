@import '../../styles/config.scss';

.score-page {
  box-sizing: border-box;

  .description {
    // padding-right: 205px;
    @media screen and (max-width: $sm) {
      padding-right: 0;
    }
  }
  .buttons {
    margin-top: 0;
  }
}
