@import '../../styles/config.scss';
* {
  outline: none;
}
b {
  font-family: $font-family-medium;
}

.upper {
  text-transform: uppercase;
}
body.no-scroll {
  overflow: hidden;
}
.nowrap {
  white-space: nowrap;
}

.gap-back {
  margin-top: -$step;
}

.gap-half {
  height: $step/2;
}

.switch-lang {
  margin-right: $step;
  position: relative;
  cursor: pointer;
  @media screen and (max-width: $xs) {
    padding-left: $step + $step/2;
  }
  img {
    width: $step;
    height: $step;
    position: absolute;
    left: -($step + $step/2);
    top: -$step/6;
    //border: 2px solid #C4C4C4;
    border-radius: 50%;
    @media screen and (max-width: $xs) {
      left: 0;
    }
  }
  .switch-lang-text {
    font-size: $font-size-0;
    color: $grey;
  }
  &:hover .switch-lang-text {
    color: $lightblue;
  }
}
@keyframes shake {
  28% {
    transform: translateX($step);
  }

  71% {
    transform: translateX(-$step/2);
  }

  92% {
    transform: translateX($step/4);
  }

  100% {
    transform: translateX(0);
  }
}

.mode-IE {
  // Override default IE and Edge styles for "Forgot" button @ log in page
  // Because IE and Edge by default shows native controls at right side
  .restore-password-link {
    color: $grey !important;
    bottom: -24px !important;
    line-height: 1 !important;
    top: unset !important;
    right: 0 !important;

    &:hover {
      color: $grey-dark !important;
    }
  }
}
