@import '../../styles/config.scss';

.bttn {
  display: inline-block;
  margin: 0 $step 0 0;
  font-family: $font-family-medium;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;

  $borderSize: 2px;

  &-size {
    &__middle {
      font-size: $font-size-1;
      padding: ($step / 2 - $borderSize) ($step - 4);
      line-height: $step;
      border-radius: $step;
    }

    &__small {
      font-size: $step / 2;
      padding: ($step / 4 - $borderSize) ($step / 2);
      line-height: $step / 2;
      border-radius: $step / 2;
    }
  }

  &-type {
    &__default {
      background-color: $lightblue;
      color: $white;
      border: $borderSize solid $lightblue;

      &:hover {
        background-color: $blue;
        color: $white;
        border-color: $blue;
      }

      &[disabled] {
        color: $white;
      }
    }

    &__bordered {
      border: $borderSize solid $grey;
      color: $grey;
      background-color: transparent;

      &:hover {
        color: $lightblue;
        border-color: $lightblue;
      }

      &[disabled] {
        background-color: $lightgrey;
      }
    }

    &__bordered-gray {
      border: $borderSize solid $grey;
      color: $grey;
      background-color: transparent;

      &:hover {
        color: $white;
        border-color: $white;
        background-color: transparent;
      }

      &[disabled] {
        background-color: transparent;
        color: $grey-dark;
        border-color: $grey-dark;
      }
    }

    &__bordered-black {
      border: $borderSize solid $black;
      color: $white;
      background-color: transparent;

      &:hover {
        border-color: $white;
        color: $white;
      }

      &[disabled] {
        color: $grey;
        border-color: $grey;
      }
    }

    &__light-gray {
      background-color: $lightgrey;
      border: $borderSize solid $lightgrey;
      color: $black;

      &:hover {
        background-color: $lightblue;
        border-color: $lightblue;
        color: $white;
      }

      &[disabled] {
        background-color: $grey-dark;
        border-color: $grey-dark;
        color: $black;
      }
    }

    &__bordered-light-gray {
      background-color: transparent;
      border: $borderSize solid $lightgrey;
      color: $lightgrey;

      &:hover {
        background-color: $lightgrey;
        border-color: $lightgrey;
        color: $black;
      }

      &[disabled] {
        background-color: transparent;
        border-color: $grey-dark;
        color: $black;
      }
    }

    &__black {
      background-color: $black;
      border: $borderSize solid $black;
      color: $white;

      &:hover {
        color: $white;
        background-color: $darkblue;
        border-color: $darkblue;
      }

      &[disabled] {
        background-color: $lightgrey;
        border-color: $lightgrey;
        color: $grey;
      }
    }

    &__white {
      background-color: $white;
      color: $lightblue;
      border: $borderSize solid $white;

      &:hover {
        background-color: $blue;
        color: $white;
        border-color: $blue;
      }

      &[disabled] {
        color: $white;
      }
    }

    &__dark-blue {
      background-color: $blue;
      color: $white;
      border: $borderSize solid transparent;

      &:hover {
        background-color: $white;
        border-color: transparent;
        color: $lightblue;
      }

      &[disabled] {
        color: $white;
      }
    }

    &__white-on-red {
      background-color: $white;
      color: $red;
      border: 2px solid transparent;

      &:hover {
        background-color: $black-opacity-50;
        border-color: transparent;
        color: $white;
      }

      &[disabled] {
        color: $red;
      }
    }

    &__white-on-gray {
      background-color: $grey;
      color: $white;
      border: 2px solid transparent;

      &:hover {
        background-color: $lightblue;
        border-color: transparent;
        color: $white;
      }

      &[disabled] {
        color: $black;
      }
    }

    &__black-on-gray {
      background-color: $grey;
      color: $black;
      border: 2px solid transparent;

      &:hover {
        background-color: $white;
        border-color: $white;
        color: $black;
      }

      &[disabled] {
        color: $black;
        border-color: $grey-dark;
        background-color: $grey-dark;
      }
    }

    &__gray {
      background-color: $grey;
      color: $black;
      border: 2px solid transparent;

      &:hover {
        background-color: $lightgrey;
        border-color: transparent;
        color: $black;
      }

      &[disabled] {
        color: $black;
      }
    }

    &__dark-gray {
      background-color: $grey-dark;
      color: $grey;
      border: 2px solid transparent;

      &:hover {
        background-color: $grey-dark;
        color: $lightgrey;
        border: 2px solid transparent;
      }

      &[disabled] {
        background-color: $grey-dark;
        color: rgba($lightgrey, 0.3);
      }
    }
  }

  &-is {
    &-disabled {
      border-color: $grey;
      background-color: $grey;
      cursor: default;
      pointer-events: none;
      user-select: none;
    }
  }
}
