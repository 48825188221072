@import '../../../../styles/config.scss';

.annotation {
  &-changing {
    .svg_container {
      opacity: 0;
    }
  }
  &-fullscreen {
    .core-wrapper {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: black;

      //TODO: add to ui lib $z-index-fullscreen
      z-index: 300;

      .core-inner {
        .svg_container {
          position: absolute;
          margin-bottom: 0;
          top: 50%;
          left: 50%;
          max-width: inherit;
          max-height: inherit;

          .image-value {
            max-height: 100%;
            max-width: 100%;
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .core-middle {
      width: 100%;
      height: 100%;
      overflow: auto;
    }

    .core-inner {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      position: relative;
    }

    .core-zoom {
      display: flex;
    }

    .buttons-outer {
      position: relative;
      // TODO: add 2 ui lib highest z-index for controls
      z-index: 450;
    }

    .svg-bottom {
      display: none;
    }

    .actions .caption,
    .actions .description {
      visibility: hidden;
      // display: none;
    }

    .svg-button-fullscreen {
      display: none;
    }

    .core-middle .core-button-fullscreenClose {
      display: block;
    }
  }
}

.buttons-outer {
  &.hide {
    animation-name: buttonsHide;
    animation-duration: 0.3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}

.annotation .core-button-fullscreenClose {
  user-select: none;
  display: none;
  position: absolute;
  right: $step;
  top: $step;
  width: $step * 2;
  height: $step * 2;
  padding: 0;
  margin: 0;
  border: 0;
  z-index: 5;
  cursor: pointer;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.core-zoom {
  user-select: none;
  display: none;
  flex-direction: column;
  position: absolute;
  left: $step;
  top: $step;
  z-index: 100;
  width: $step * 2;

  &-percentage {
    width: 100%;
    text-align: center;
    color: $grey;
    margin-top: 16px;
  }
}

.core-slider {
  background-color: #444;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
  margin-bottom: 20px;
  border-radius: 100px;
  .rc-slider-track {
    background-color: #1356a8;
  }

  .rc-slider-handle:hover {
    border-color: lighten(#1356a8, 10%);
  }

  .rc-slider-handle {
    border: solid 2px #1356a8;
    background-color: #fff;
  }
}

.core-button-zoom {
  &-in,
  &-out {
    position: relative;
    width: $step * 2;
    height: $step * 2;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &-in {
    border-radius: 100px 100px 0 0;
  }

  &-out {
    border-radius: 0 0 100px 100px;
  }
}

.svg-button-fullscreen {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  color: #ddd;
  cursor: pointer;

  &:hover {
    color: #fafafa;
  }

  svg {
    width: 24px;
    height: 24px;
    display: block;
  }
}

@keyframes buttonsHide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    display: none;
  }
}
