@import '../../../../styles/config.scss';

.svg-bottom {
  $svgBottomHeight: 15px; // высота текста

  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: $step / 2;
  height: $svgBottomHeight;

  .links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;

    &-left {
      margin-bottom: 12px;
      .link {
        margin-left: -24px;
        margin-right: $step / 2;
      }
    }

    &-right {
      margin-bottom: 12px;
      .link {
        &:last-child {
          margin-right: -24px;
        }
      }
    }

    .link {
      line-height: 24px;

      &.toggler {
        &.active {
          color: $black;

          &:hover {
            color: $black;
          }
        }

        &:hover {
          color: $lightblue;
        }
      }
    }

    .icon {
      float: left;
      margin-right: $step / 4;
    }
  }
}
