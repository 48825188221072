@import '../../styles/config.scss';

.create-password-page {
  h1 {
    line-height: $font-size-5;
    margin-bottom: $step * 3 - 11px;
  }
  .field-error {
    color: $red;
    position: absolute;
    top: 40px;
    &-form {
      position: relative;
      top: auto;
      padding: $step/2;
    }
  }
}
