@import '../../../../styles/config.scss';

.label-text-page {
  .textarea-form {
    margin-top: $step * 2;
  }

  .textarea-wrapper {
    margin-bottom: $step * 2;
    @media screen and (max-width: $xs) {
      margin-bottom: $step;
    }

    > textarea {
      display: block;
    }

    .field-input-wrapper {
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .text-value {
      height: $step * 2;
      line-height: $step * 2;
    }

    .field-error {
      display: none;
    }
  }

  .field-buttons {
    margin-bottom: $step/2;
  }
}

.image-rotate {
  &-0 {
    transform: rotate(0deg);
  }
  &-90 {
    transform: rotate(90deg);
    max-width: calc(100vh - 96px - 24px - 39px) !important;
  }
  &-180 {
    transform: rotate(180deg);
  }
  &-270 {
    transform: rotate(270deg);
    max-width: calc(100vh - 96px - 24px - 39px) !important;
  }
}

.image-value_content.image-value_content__pdf {
  width: calc(4 * (100vw - 96px - 5 * 48px) / 6 + 48px * (4 - 1));
  height: calc(100vh - 96px - 24px);
}

.ocr-multiple-bad {
  .title {
    position: relative;

    &__hidden {
      height: 20px;
      margin-bottom: 36px;
      display: flex;
      align-items: center;
    }

    &-toggler {
      width: 0;
      height: 0;
      border-style: solid;
      display: block;
      position: absolute;
      bottom: 6px;
      right: 0;
      cursor: pointer;

      &.up {
        border-width: 0 10px 15px 10px;
        border-color: transparent transparent #111111 transparent;
      }
      &.down {
        border-width: 15px 10px 0 10px;
        border-color: #111111 transparent transparent transparent;
      }
    }

    &-line {
      width: 90%;
      height: 2px;
      font-size: 0;
      background: #111111;
      margin-top: -10px;
    }
  }

  .textarea-form {
    .field-input-wrapper {
      margin-bottom: 2px;

      input {
        height: 30px;
        line-height: 30px;
        text-transform: uppercase;

        &[type='date'] {
          width: 100%;
          padding: 0 12px;
          font-family: TTNorms, Noto Sans SC, sans-serif;
          font-size: 16px;
          border: none;
          background: #ddd;
          box-sizing: border-box;
          outline: none;
        }
      }
    }

    .field-input-label {
      padding-bottom: 0;
    }

    .low-confidence {
      color: red;
    }
  }

  .buttons {
    margin-top: 0;

    .btn {
      width: 100%;
    }
  }
  .field {
    margin: 0 0 12px;
  }

  .annotation {
    position: relative;
  }

  .timer {
    position: absolute;
    top: 0;
    width: 0%;
    height: $step;
    z-index: 1;

    > span {
      line-height: 24px;
      padding: 0 5px;
      white-space: nowrap;
      font-size: 1.25em;
    }

    &.init {
      animation: time 45s ease-out forwards;
      max-width: 100%;
    }

    &.pause {
      animation-play-state: paused;
    }

    @keyframes time {
      0% {
        width: 0%;
        background-color: green;
      }

      50% {
        background: yellow;
      }

      75% {
        background-color: red;
      }

      100% {
        width: 100%;
        background-color: red;
      }
    }
  }
}

.ocr {
  width: 100%;
}

.textarea-wrapper {
  padding: 0 32px 0;
}

.textarea-form {
  .buttons {
    padding: 32px;
    border-top: 1px solid $grey;
  }
}
