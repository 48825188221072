@keyframes shakeShort {
  28% {
    transform: translateX($step);
  }

  71% {
    transform: translateX(-$step/2);
  }

  92% {
    transform: translateX($step/4);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes shakeLong {
  15% {
    transform: translateX(-$step);
  }

  30% {
    transform: translateX($step);
  }

  45% {
    transform: translateX(-$step/2);
  }

  60% {
    transform: translateX($step/2);
  }

  75% {
    transform: translateX(-$step/3);
  }

  90% {
    transform: translateX($step/3);
  }
}

i._effect {
  display: inherit;
  font-size: inherit;
  font-style: inherit;
  flex-direction: inherit;
  will-change: transform;
  transform: translate(0, 0);
  backface-visibility: hidden;

  &-shakeShort {
    animation: shakeShort 0.3s ease-in both;
  }

  &-shakeLong {
    animation: shakeLong 0.75s ease-out forwards;
  }
}
