.container-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  @media screen and (max-width: $xs) {
    min-height: calc(100vh - 88px);
  }

  body.fixed-container & {
    overflow: hidden;
  }

  &.is-bg {
    filter: blur(2.5px);
  }

  & > .container {
    flex: 1 0 auto;
    padding: $step * 4 $step * 2;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (min-width: $sm) {
      body.fixed-container & {
        padding-bottom: 0;
      }
    }
    @media screen and (min-width: 1280px) {
      width: $max-container-width;
      margin-left: auto;
      margin-right: auto;
    }
    @media screen and (max-width: $sm) {
      width: 100%;
      padding: $step * 2 $step;
    }

    @media screen and (max-width: $xs) {
      width: 100%;

      body.fixed-container & {
        padding-bottom: 0;
      }
    }

    .container-inner {
    }
  }
}

.grid-container {
  padding-right: $step * 2;
  padding-left: $step * 2;
  box-sizing: border-box;

  @media screen and (min-width: 1280px) {
    width: $max-container-width;
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (max-width: $sm) {
    width: 100%;
    padding-right: $step;
    padding-left: $step;
  }

  @media screen and (max-width: $xs) {
    width: 100%;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
}

@for $i from 1 through 6 {
  $dx: 2 * $step;
  .col-#{$i} {
    // 100vw - padding-left: 48px - padding-right: 48px - ширина между колонками + ширина между колонками, для col > 1
    width: calc(#{$i} * (100vw - 96px - 5 * #{$dx}) / 6 + #{$dx} * (#{$i} - 1));
    min-height: 1px;
    &:not(:first-child) {
      margin-left: 2 * $step;
    }
  }
}

@media screen and (min-width: $max-container-width) {
  @for $i from 1 through 6 {
    $dx: 2 * $step;
    .col-#{$i} {
      // max-width(1280px) - padding-left: 48px - padding-right: 48px - ширина между колонками + ширина между колонками, для col > 1
      width: calc(#{$i} * (1184px - 5 * #{$dx}) / 6 + #{$dx} * (#{$i} - 1));
      min-height: 1px;
      &:not(:first-child) {
        margin-left: 2 * $step;
      }
    }
  }
}

@media screen and (max-width: $sm) {
  .sm-hidden {
    display: none !important;
  }
  @for $i from 1 through 4 {
    // ширина одной колонки
    $oneCol: calc((100vw - 5 * #{$step}) / 4);
    .col-sm-#{$i} {
      width: calc(#{$i} * #{$oneCol} + #{$step} * (#{$i} - 1));
    }
  }
  .sm-hidden + div[class*='col-sm-'] {
    margin-left: 0;
  }
  div[class*='col-sm-'] + div[class*='col-sm-'] {
    margin-left: $step;
  }
  div[class*='col-sm-'] + div.col-sm-4 {
    margin-left: 0;
  }
}
@media screen and (max-width: $xs) {
  .xs-hidden {
    display: none !important;
  }
  .xs-hidden + div[class*='col-xs-'] {
    margin-left: 0 !important;
  }
  .col-xs-1 {
    width: calc((100vw - #{$step} * 3) / 2);
  }
  .col-xs-2 {
    width: 100%;
    &:not(:first-child) {
      margin-left: 0;
    }
  }
  div[class*='col-xs-'] + div[class*='col-xs-'] {
    margin-left: $step;
  }
  div[class*='col-xs-'] + div.col-xs-2 {
    margin-top: 2 * $step;
    margin-left: 0;
  }
}

.gap-half {
  height: 12px;
  overflow: hidden;
  display: block;
}

@for $i from 1 through 8 {
  .gap-#{$i} {
    height: #{$i * $step};
    overflow: hidden;
    display: block;
  }
}

@media screen and (min-width: $sm) {
  .sm-hidden-up {
    display: none !important;
  }
}

@media screen and (min-width: $xs) {
  .xs-hidden-up {
    display: none !important;
  }
}
