@import '../../styles/config.scss';

.welcome {
  box-sizing: border-box;
  @media screen and (max-width: $xs) {
    margin-bottom: $step-mobile * 5.5;
  }

  h1 {
    // margin-top: 105px;
    @media screen and (max-width: $sm) {
      margin-top: 106px;
    }
  }
}
