.radio-wrapper {
  position: absolute;
  left: 0;
  top: -3px;

  .input-image {
    cursor: pointer;
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 6px;
    position: absolute;
    left: 2px;
    top: 2px;
    box-sizing: border-box;
    border: 2px solid $lightgrey;
  }
  input[type='radio'] {
    display: none;
  }
  input[type='radio']:checked + .input-image {
    border: 4px solid $lightblue;
  }
}
