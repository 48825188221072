@import '../../../../styles/config.scss';

.label-text-page {
  .textarea-form {
    margin-top: $step * 2;
  }

  .textarea-wrapper {
    margin-bottom: $step * 2;
    @media screen and (max-width: $xs) {
      margin-bottom: $step;
    }

    > textarea {
      display: block;
    }

    .field-input-wrapper {
      margin-bottom: $step - 4;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .text-value {
      height: $step * 2;
      line-height: $step * 2;
    }

    .field-error {
      display: none;
    }
  }

  .field-buttons {
    margin-bottom: $step/2;
  }
}
