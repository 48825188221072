@import '../../styles/config.scss';

.right-sidebar-help {
  div.sidebar-inner {
    background-color: $lightgrey;
    padding-bottom: $step * 5;
    ul {
      list-style-type: none;

      li {
        position: relative;

        .bullet {
          font-size: 12px;
          top: -1px;
          color: $black;
          position: relative;
        }
      }
    }
    .icon-close {
      position: absolute;
      right: $step;
      top: $step;
      cursor: pointer;
      outline: none;
      fill: $grey;

      &:hover {
        fill: $lightblue;
      }
    }
  }
  .contacts {
    margin-top: $step + $step/6;
  }
}

.key-group {
  display: flex;
  align-items: center;
  margin: $step / 4 * 3 0;

  .key-buttons {
    display: flex;
    align-items: center;
    min-width: $step * 5.5;
    margin-right: $step * 2;

    &-item {
      width: $step * 2;
      height: $step * 2;
      background: $black;
      border-radius: $step / 4;
      color: $white;
      font-weight: 500;
      line-height: $step * 2;
      text-align: center;
      margin: $step / 8;

      &__size {
        &-3 {
          width: $step * 3;
        }
        &-4 {
          width: $step * 4;
        }
      }
    }
    &-text {
      margin: 0 $step / 4;
      font-weight: 500;
    }
  }
}
