@keyframes spinnerRotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spinnerItem {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 1;
  }
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

._spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
._spinner {
  backface-visibility: hidden;
  will-change: transform;
  transform: rotate(0deg);

  &_active {
    $segmentDuration: 0.75s;
    $segmentsQty: 12;
    $rotateDuration: $segmentDuration * 2 -
      ($segmentDuration / $segmentsQty * 2);

    animation: spinnerRotate $rotateDuration linear infinite;

    & > path {
      @for $i from 1 through $segmentsQty {
        @if $i % 2 == 0 {
          &:nth-child(#{$i}) {
            $delay: $segmentDuration / ($segmentsQty / 2) * (($i - 1) / 2);
            animation: spinnerItem $segmentDuration linear $delay normal;
          }
        } @else {
          &:nth-child(#{$i}) {
            $delay: $segmentDuration /
              ($segmentsQty / 2) *
              (($i - 1) / 2) +
              $segmentDuration *
              2;
            animation: spinnerItem $segmentDuration linear $delay normal;
          }
        }
      }
    }
  }

  > path {
    opacity: 0;

    &:nth-child(odd) {
      fill: $lightblue;
    }
    &:nth-child(even) {
      fill: $red;
    }
  }
}
