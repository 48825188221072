@import '../../styles/config.scss';

.right-sidebar {
  .sidebar-override {
    display: none;
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(250, 250, 250, 0.5);
  }
  .sidebar-content {
    width: 378px;
    max-width: 100%;
  }
  .sidebar-inner {
    opacity: 0;
    z-index: 100;
    font-weight: normal;
    position: fixed;
    top: 2 * $step;
    bottom: 0;
    //width: calc(100% - (10% + (80% - 7 * 48px) * 4 / 6 + 4 * 48px));
    //right: calc((10% + (80% - 7 * 48px) * 4 / 6 + 4 * 48px) - 100%);
    left: 100%;
    box-sizing: border-box;
    padding: $step * 2;
    transition: transform $speed, opacity $speed;
    font-size: $font-size-1;
    line-height: $font-size-2;
    overflow-x: hidden;
    overflow-y: auto;

    //@media screen and (max-width: $xs) {
    //  max-width: calc(100% - #{$step*2});
    //}
  }
  h1 {
    margin-top: $step;
    @media screen and (max-width: $xs) {
      line-height: $step;
      margin-top: $step;
    }
  }
  h2 {
    margin-top: $step * 2;
    height: $step;
    @media screen and (max-width: $xs) {
      margin-top: $step/12 * 11;
    }
  }
  &-state-active {
    .sidebar-inner {
      opacity: 1;
      transform: translate(-100%, 0);
      @media screen and (max-width: $xs) {
        width: 100%;
        .sidebar-content {
          width: 100%;
        }
      }
    }
    .sidebar-override {
      display: block;
    }
  }
}
