@import '../../styles/config.scss';
@import '../../styles/grid.scss';

.heading {
  background-color: $black;
  padding: ($step / 2) 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  z-index: $z-index-header;
  min-height: $step;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;

  @media screen and (max-width: $xs) {
    padding: $step / 2;
  }

  &-avatar {
    width: $step;
    height: $step;
    border-radius: 50%;
    margin-right: $step / 2;
  }

  &-logo {
    display: block;
    height: 22px;
    left: $step;
    position: absolute;
    z-index: 1;

    @media screen and (max-width: $xs) {
      left: $step / 2;
    }
  }

  &-menu {
    width: 1280px;
    padding: 0 $step * 2;
    position: relative;

    @media screen and (max-width: $xs) {
      display: none;
    }

    &-inner {
      margin-left: calc(1 * (100vw - 96px - 5 * #{$step * 2}) / 6);
      padding: 0 $step * 2;
      display: flex;
      align-items: center;
      position: relative;
      min-height: $step;

      @media screen and (max-width: $sm) {
        $oneCol: calc((100vw - 5 * #{$step}) / 4);

        margin-left: calc(1 * #{$oneCol});
        padding-left: 0;
      }
    }

    &-item {
      color: $grey;
      margin-right: $step;

      &-profile {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;

        @media screen and (max-width: $sm) {
          left: calc(1.5 * calc((100vw - 5 * 24px) / 4));
          margin-left: $step / 2;
        }
      }

      &:hover,
      &-active {
        color: $white;
      }
    }
  }

  &-extra {
    position: absolute;
    right: $step;
    display: flex;
    align-items: center;

    @media screen and (max-width: $xs) {
      right: $step / 2;
    }

    .bttn {
      margin: 0;
    }

    &-mobile-caller {
      display: none;
      margin-left: $step / 2;
      margin-right: -($step / 2);

      @media screen and (max-width: $xs) {
        display: block;
      }
    }
  }

  &-mobile-menu {
    .heading-mobile-menu-content {
      display: flex;
      align-items: center;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      margin-top: -$step;
    }

    .heading-menu-item {
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      margin: 0 $step $step * 2.5;

      &:last-child {
        margin-bottom: 0;
      }

      &-profile {
        position: static;
        display: flex;

        .heading-avatar {
          width: $step * 1.5;
          height: $step * 1.5;
        }
      }
    }
  }
}
