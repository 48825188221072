.tip {
  position: relative;
  display: inline-block;

  &-container {
    position: relative;
    display: inline-block;
  }

  &-content {
    background: $black;
    color: $white;
    font-size: $font-size-0;
    position: absolute;
    line-height: $font-size-0 + $step / 6;
    padding: $step / 6;
    backface-visibility: hidden;
    display: none;

    $offset: $step / 3;

    &.tip-visible {
      opacity: 0;
      display: block;
      position: absolute;
      animation-name: show;
      animation-duration: $speed;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      animation-delay: 0.5s;
    }

    &.tip-top {
      top: -$step;
      left: 50%;
      transform: translate(-50%, -$offset);
    }

    &.tip-top-left {
      top: -$step;
      transform: translate(0, -$offset);
    }

    &.tip-top-right {
      top: -$step;
      right: 0;
      transform: translate(0, -$offset);
    }

    &.tip-bottom {
      left: 50%;
      transform: translate(-50%, $offset);
    }

    &.tip-bottom-left {
      transform: translate(0, $offset);
    }

    &.tip-bottom-right {
      right: 0;
      transform: translate(0, $offset);
    }

    &.tip-left {
      bottom: -50%;
      left: 0;
      transform: translateX(calc(-100% - #{$offset}));
    }

    &.tip-right {
      bottom: -50%;
      right: 0;
      transform: translateX(calc(100% + #{$offset}));
    }
  }

  @keyframes show {
    0% {
      opacity: 0;
      display: inline-block;
    }

    100% {
      opacity: 1;
      display: inline-block;
    }
  }
}
