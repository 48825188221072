.table {
  font-size: $font-size-1;
  text-align: left;
  line-height: $step * 1.5;
  width: 100%;

  tr th {
    color: $grey;
    font-family: $font-family-medium;
  }
}
.table-col-2 tr td,
.table-col-2 tr th {
  width: 50%;
}
