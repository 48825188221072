@import '../../../../../styles/config.scss';

.tableButton {
  display: flex;
  align-items: center;
  padding: 0px 12px;
  height: 40px;
  background: $light-grey-bg;
  border-radius: 4px;
  border: 1px solid $light-grey-bg;
  outline: none;
  font-family: $font-family-auto;
  font-weight: 400;
  font-size: 16px;
  text-align: start;
  cursor: pointer;
  margin-top: 18px;

  &:hover {
    border: 1px solid $accent;
  }
}

.tablePopup {
  background-color: #555555;
  padding: 0 !important;
  margin: 48px 0 0 0 !important;
}

.tablePopupContainer {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 48px);
}

.tablePopupTop,
.tablePopupBottom {
  flex: 1;
  max-height: 50%;
}

.tablePopupTop {
  overflow-y: hidden;
  padding: 24px 52px;
}
.tablePopupBottom {
  background-color: #fafafa;
  padding: 24px 32px;
}
