@import '../../styles/config.scss';

.verification {
  max-width: 591px;
  .desc {
    font-size: $font-size-2;
    line-height: $step * 3/2;
    font-weight: 400;
    margin-top: $step + $step/12 * 5;
    @media screen and (max-width: $xs) {
      margin-top: $step + $step/12 * 7;
    }
  }
  .buttons {
    margin-top: $step/2 * 3;
  }
}
