.popup {
  margin: $step * 2 auto;
  color: $black;
  position: relative;
  width: 100%;
  padding-left: $step;
  padding-right: $step;
  z-index: $z-index-popup;
  box-sizing: border-box;

  @media screen and (max-width: $sm) {
    min-height: 100vh;
    padding-left: $step/2;
    padding-right: $step/2;
  }

  &-container {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 150;

    .icon-close {
      outline: none;
      position: fixed;
      right: $step;
      top: $step * 3;
      cursor: pointer;
      z-index: 200;
    }
  }

  &-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background: $lightgrey;
  }

  &-state-open {
    display: block;
  }
}
