.icon {
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  &-twitter {
    top: -2px;
    width: 32px;
    height: 26px;
    background: url('../images/twitter.svg');
    background-size: 32px 26px;
  }
  &-facebook {
    top: -2px;
    background: url('../images/facebook.svg');
    background-size: 24px 24px;
  }
  &-close {
    width: 18px;
    height: 18px;
    background: url('../images/plus-gray.svg');
    background-size: 18px 18px;
    transform: rotate(45deg);
    &:hover {
      background: url('../images/plus-black.svg');
      background-size: 18px 18px;
    }
  }
  &-success {
    background: url('../images/success.svg');
    background-size: 24px 24px;
  }
}
