.buttons {
  margin-top: $step * 2;
  //@media screen and (max-width: $xs) {
  //  margin-top: $step-mobile*2;
  //}
  &-pull-right {
    text-align: right;
    & > *:last-child {
      margin-right: 0;
    }
  }
}
.btn,
.btn-border,
.btn-small {
  font-family: $font-family-medium;
  height: 2 * $step;
  line-height: 2 * $step - 2px;
  padding: 1px ($step - 4px);
  display: inline-block;
  margin: 0 $step 0 0;
  font-size: $font-size-1;
  background-color: $lightblue;
  border-radius: 73px;
  border: none;
  color: $white;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;

  &:hover {
    background-color: $blue;
    color: $white;
  }
  &[disabled] {
    background-color: $grey;
    cursor: default;
    pointer-events: none;
  }
}
.btn-border[disabled] {
  background-color: transparent;
}
.btn-transparent,
.btn-transparent:hover {
  padding-left: 0;
  padding-right: 0;
  background: none;
  border: none;
  cursor: default;
}
.btn-small {
  font-size: $step/2;
  padding: 0 10px;
  height: $step;
  line-height: $step;
  //@media screen and (max-width: $xs) {
  //  height: $step-mobile;
  //  line-height: $step-mobile;
  //}
}
.btn-border {
  background-color: transparent;
  color: $grey;
  border: 2px solid $grey;
  line-height: 2 * $step - 6px;
  &:hover {
    border-color: $lightblue;
    background-color: transparent;
    color: $lightblue;
  }
}
.btn-border-white {
  background-color: transparent;
  color: $white;
  border: 2px solid $white;
  line-height: 2 * $step - 6px;
  &:hover {
    border-color: $lightblue;
    background-color: transparent;
    color: $lightblue;
  }
}
.btn-border-blue {
  background-color: transparent;
  color: $lightblue;
  border: 2px solid $lightblue;
  line-height: 2 * $step - 6px;
  &:hover {
    border-color: $blue;
    background-color: transparent;
    color: $blue;
  }
}
.btn-border-red {
  background-color: transparent;
  color: $red;
  border: 2px solid $red;
  line-height: 2 * $step - 6px;
  &:hover {
    border-color: $darkred;
    background-color: transparent;
    color: $darkred;
  }
}
.btn-white {
  background-color: $white;
  color: $lightblue;
  &:hover {
    background-color: $blue;
    color: $white;
  }
}
.btn-blue {
  background-color: $blue;
  color: $white;
  &:hover {
    background-color: $white;
    color: $lightblue;
  }
}
.btn-white-on-red {
  background-color: $white;
  color: $red;
  &:hover {
    background-color: $black-opacity-50;
    color: $white;
  }
}
.btn-gray {
  background-color: $grey;
  color: $black;
  &:hover {
    background-color: $lightgrey;
    color: $black;
  }
}

.btn-dark-gray {
  background-color: $grey-dark;
  color: $grey;

  &:hover {
    background-color: $grey-dark;
    color: $lightgrey;
  }

  &[disabled] {
    background-color: $grey-dark;
    color: rgba($lightgrey, 0.3);
  }
}
.btn-login {
  background-color: $black;
}
