@import 'static/styles/checkbox';
@import 'static/styles/radio';
@import 'static/styles/captcha';

input[type='text'],
input[type='password'],
input[type='email'],
textarea,
select {
  width: 100%;
  padding: 0 $step/2;
  font-family: $font-family-auto;
  font-size: $font-size-1;
  height: $step * 2;
  line-height: $step * 2;
  //@media screen and (max-width: $xs) {
  //  & {
  //    height: $step-mobile*2;
  //    line-height: $step-mobile*2;
  //    font-size: $font-size-0;
  //    padding: 0 $step-mobile/2;
  //  }
  //}
  border: none;
  background: $lightgrey;
  box-sizing: border-box;
  outline: none;
  &:-webkit-autofill {
    background-color: $white !important;
    -webkit-box-shadow: inset 0 0 0 50px $white;
    -webkit-text-fill-color: $black;
  }
}
select {
  border-radius: 0;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  position: relative;
  padding-right: $step;
}
.select-wrapper {
  position: relative;
  &::after {
    pointer-events: none;
    content: '';
    position: absolute;
    right: 0;
    top: $step/2;
    width: $step;
    height: $step;
    // background: url('../../static/dropdown.svg');
    background-size: $step $step;
  }
}
textarea {
  height: 144px;
  resize: vertical;
  @media screen and (max-width: $xs) {
    & {
      height: 140px;
    }
  }
}
.field {
  position: relative;

  //@media screen and (max-width: $xs) {
  //    margin-bottom: $step-mobile;
  //}

  &-input {
    &-wrapper {
      margin-bottom: 24px;
    }
  }

  &-label {
    display: inline-block;
    color: $grey;
    line-height: $step / 2;
    font-size: $font-size-01;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: $font-family-bold;
    padding-top: 2px;
    padding-bottom: 10px;
  }
}
.field-error {
  padding: $step/2 0 0;
  font-size: $font-size-0;
  line-height: 18px;
}
.is-invalid-input {
  box-shadow: inset 0px 0px 0px 2px $red;
}
.input-image.is-invalid-input {
  border-color: $red;
  box-shadow: none;
}

.textarea-autosize {
  resize: none;
  line-height: $step;
  padding-top: $step/2;
  padding-bottom: $step/2;
  box-sizing: border-box;
}
