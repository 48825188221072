@import '../../styles/config.scss';

.user-form {
  width: 100%;
  background-color: $white;
  box-sizing: border-box;
  padding: 47px $step $step;
  height: $step * 24;
  @media screen and (max-width: $xs) {
    padding: 35px $step-mobile $step-mobile;
    height: $step-mobile * 20;
    &.log-in {
      height: $step-mobile * 24 + $step-mobile/2;
    }
  }

  input[type='text'],
  input[type='password'],
  input[type='email'],
  textarea,
  select {
    &:-webkit-autofill {
      background-color: $lightgrey !important;
      -webkit-box-shadow: inset 0 0 0 50px $lightgrey;
      -webkit-text-fill-color: $black;
    }
  }

  form {
    position: relative;
    .dbrain-captcha {
      .reload-wrapper {
        .link {
          position: absolute;
          right: 0;
        }
      }
    }
  }

  .title {
    & + .subtitle {
      margin-top: -17px;
    }
  }
  .subtitle {
    font-size: $font-size-1;
    line-height: $step;
    margin-bottom: 41px;
    font-weight: 400;
    & + .buttons {
      margin-top: 41px;
    }
  }
  input[type='text'],
  input[type='password'] {
    font-weight: normal;
    outline: none;
    text-decoration: none;
    border: none;
    background-color: $lightgrey;
    line-height: $step * 2;
    padding: 0 $step/2;
    box-sizing: border-box;
    display: block;
    width: 100%;
    margin-bottom: $step;
    @media screen and (max-width: $xs) {
      margin-bottom: $step-mobile;
    }

    &[name='password'] {
      margin-bottom: $step/2;
      @media screen and (max-width: $xs) {
        margin-bottom: $step-mobile;
      }
    }
  }
  form > div {
    // for .field-error outputs
    position: relative;
  }
  .field-error {
    color: $red;
    position: absolute;
    top: 36px;
    &-login-form {
      top: -36px;
    }
  }
  .agree {
    margin-top: 188px;
    font-size: $font-size-01;
    color: $grey;
    .terms {
      color: $grey;
      font-family: $font-family-medium;
      &:hover {
        color: $lightblue;
      }
    }
  }

  .field-login-password {
    position: relative;

    .restore-password-link {
      position: absolute;
      top: 0;
      right: $step;
      font-size: $font-size-0;
      line-height: $step * 2;
      color: $lightblue;
      cursor: pointer;

      &:hover {
        color: $blue;
      }
    }
  }
}

.dbrain-captcha .captcha-field .reload-captcha {
  background-image: url('../../static/images/reload-gray.svg');
  &:hover {
    background-image: url('../../static/images/reload-lightgray.svg');
  }
}
