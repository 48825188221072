@import '../../styles/config.scss';

.no-tasks-page {
  box-sizing: border-box;

  .description {
    // padding-right: 205px;

    @media screen and (max-width: $sm) {
      padding-right: 0;
    }

    .link-text,
    .icon {
      outline: none;
      margin: 0 2px;
    }
  }

  .buttons {
    margin-top: 0;
  }
}
