@font-face {
  font-family: 'TTNorms';
  src: url('./358409_F_0.woff2') format('woff2'),
    url('./358409_F_0.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'TTNorms';
  src: url('./358409_9_0.woff2') format('woff2'),
    url('./358409_9_0.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'TTNorms';
  src: url('./358409_D_0.woff2') format('woff2'),
    url('./358409_D_0.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'TTNorms';
  src: url('./358409_1_0.woff2') format('woff2'),
    url('./358409_1_0.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'TTNorms-Medium';
  src: url('./358409_D_0.woff2') format('woff2'),
    url('./358409_D_0.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'TTNorms-Medium';
  src: url('./358409_10_0.woff2') format('woff2'),
    url('./358409_10_0.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'TTNorms-Bold';
  src: url('./358409_1_0.woff2') format('woff2'),
    url('./358409_1_0.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'TTNorms-Bold';
  src: url('./358409_4_0.woff2') format('woff2'),
    url('./358409_4_0.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'TTNorms-Light';
  src: url('./358409_C_0.woff2') format('woff2'),
    url('./358409_C_0.woff') format('woff');
  font-style: normal;
}
@font-face {
  font-family: 'TTNorms-Light';
  src: url('./358409_B_0.woff2') format('woff2'),
    url('./358409_B_0.woff') format('woff');
  font-style: italic;
}
