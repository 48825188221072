@import '../../styles/config.scss';

.right-sidebar-authorization {
  div.sidebar-inner {
    min-height: 100vh;
    background-color: $white;
    padding-bottom: $step * 5;
    top: 0;

    .icon-close {
      position: absolute;
      right: $step;
      top: $step;
      cursor: pointer;
      outline: none;
      fill: $grey;

      &:hover {
        fill: $lightblue;
      }
    }

    .checkbox-wrapper {
      top: 3px;

      .input-image {
        border-color: $black;
        &.is-invalid-input {
          border-color: $red;
        }
      }
    }
  }
}
