@import '../../styles/config.scss';

.faq-page {
  .faq-list {
    padding-top: 2px;
  }

  .faq-item {
    margin-bottom: $step;

    &-title {
      padding-bottom: 5px;
    }
  }

  .faq-block {
    margin-bottom: 50px;
    position: relative;

    &-anchor {
      position: absolute;
      top: -($step * 4);
    }

    &-heading {
      font-weight: 500;
      font-size: 24px;
      line-height: 1;
      color: $black;
      margin-bottom: 46px;
    }
  }

  .left-navigation {
    position: fixed;
    display: flex;
    justify-items: center;
    align-items: center;
    color: #aaa;
    top: 0;
    bottom: 0;

    &-inner {
      position: relative;
      top: -7px;
    }

    &-item {
      margin: 0;
      line-height: 24px;
      font-size: 12px;
      padding-top: 7px;
      display: block;
      padding-bottom: 17px;
      color: $grey;
      cursor: pointer;

      &.active {
        color: $lightblue;
      }

      &:hover {
        color: $black;
      }
    }
  }
}
