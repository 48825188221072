@import '../../styles/config.scss';

.error-page {
  box-sizing: border-box;
  color: $white;
  h1 {
    white-space: pre-line;
  }
  .message {
    a {
      color: $lightgrey;
      &:hover {
        color: $white;
      }
    }
  }
  .link-white {
    border-bottom: 2px solid transparent;
    &:hover {
      border-bottom: 2px solid $white;
    }
  }
}
