@import '../../../../styles/config.scss';
$rainbow: #ff303f #139eef #f99018 #12edba #ff44c0 #212fff #fccc20 #bc19fc
  #14e814 #e04a02 #02c5e0 #fbab84 #5a135b #b6000d #99c224 #ff303f #139eef
  #f99018 #12edba #ff44c0 #212fff #fccc20 #bc19fc #14e814 #e04a02 #02c5e0
  #fbab84 #5a135b #b6000d #99c224 #ff303f #139eef #f99018 #12edba #ff44c0
  #212fff #fccc20 #bc19fc #14e814 #e04a02 #02c5e0 #fbab84 #5a135b #b6000d
  #99c224;

.tool-multilabel {
  .buttons.marks {
    .rainbow {
      @for $i from 1 through length($rainbow) {
        $c: nth($rainbow, $i);

        &:nth-of-type(#{$i}).exists {
          background-color: $c;
        }
      }
    }
  }

  .buttons.marks.grouped {
    margin-top: $step * 1.5;

    .buttons-title {
      padding-top: 0;
      padding-bottom: $step / 2;
    }

    @each $c in $rainbow {
      $i: index($rainbow, $c);

      &:nth-of-type(#{$i}) {
        &.grouped__active {
          color: $c;
        }

        @for $i from 1 through length($rainbow) {
          $c: nth($rainbow, $i);

          &:nth-of-type(#{$i}) {
            &.grouped__active {
              color: $c;
            }

            .rainbow {
              color: $black;
              background-color: $lightgrey;
              border-color: $lightgrey;

              &.exists {
                background-color: $c;
                color: $white;
                border-color: transparent;
              }

              &:hover,
              &.pressed {
                color: $white;
                background-color: $c;
                border-color: $c;
              }
            }
          }
        }
      }
    }
  }
}
