@import '../../../../styles/config.scss';

.dropdown {
  display: none;
  position: fixed;
  top: $step * 2;
  bottom: 0;
  right: 0;
  left: 0;
  background: $black;
  overflow: hidden;
  z-index: 10;

  &-open {
    display: block;
  }
}
