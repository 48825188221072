.spinner {
  display: flex;
  justify-content: center;
}

.root {
  max-height: 100%;
}

.imageContainer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.loader {
  width: 100%;
  height: calc(100vh - 120px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  transition: 0.3s ease all;
  cursor: pointer;
  opacity: 0;
}

.icon svg {
  width: 16px;
  height: 16px;
  fill: rgba(0, 0, 0, 0.6);
}

.icon:hover {
  background-color: rgba(255, 255, 255, 1);
}

.root:hover .icon {
  opacity: 1;
}

.image {
  cursor: pointer;
  max-width: 100%;
  max-height: 100%;
}

@media (max-width: 768px) {
  .loader {
    height: 60vh;
  }
}
