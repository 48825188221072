.dbrain-captcha {
  .captcha-field {
    display: flex;
    .captcha-wrapper {
      background: $white;
      position: relative;
      padding-right: 27px;
      display: flex;
      justify-content: center;
      flex: 1;
      height: 48px;
      .image {
        height: 48px;
        max-width: 144px;
        flex: 1;
      }
    }
    .input-wrapper {
      flex: 1;
      display: flex;
      align-items: center;
      padding-left: 2px;
      position: relative;
      .captcha-value {
        margin-bottom: 0;
      }
    }
    .reload-captcha {
      position: absolute;
      z-index: 1;
      top: 17px;
      right: 12px;
      width: 14px;
      height: 15px;
      cursor: pointer;
      background: url('../images/reload-lightgray.svg');
      &:hover {
        background: url('../images/reload-gray.svg');
      }
    }
    .captcha-error {
      color: $red;
      flex: 1;
    }
  }
}
