@import '../../styles/config.scss';

.authorization-form-wrapper {
  input[type='text'],
  input[type='password'],
  input[type='email'],
  textarea,
  select {
    &:-webkit-autofill {
      background-color: $white !important;
      -webkit-box-shadow: inset 0 0 0 50px $white;
      -webkit-text-fill-color: $black;
    }
  }

  .authorization-form-error.field-error {
    top: -1 * $step;
  }

  form {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .field-login-password {
      position: relative;
      .restore-password-link {
        position: absolute;
        top: 0;
        right: $step/2;
        font-size: $font-size-0;
        line-height: $step * 2;
        color: $lightgrey;
        cursor: pointer;

        &:hover {
          color: $grey;
        }
      }
    }
  }

  input[type='email'],
  input[type='text'],
  input[type='password'] {
    font-weight: normal;
    outline: none;
    text-decoration: none;
    border: none;
    line-height: $step * 2;
    padding: 0 $step/2;
    box-sizing: border-box;
    display: block;
    width: 100%;
    margin-bottom: $step/2;
    background-color: $white;
    @media screen and (max-width: $xs) {
      margin-bottom: $step;
    }

    &[name='password'] {
      margin-bottom: $step/2;
    }
  }
  form > div {
    // for .field-error outputs
    position: relative;
  }
  .field-error {
    color: $accent;
    position: absolute;
    top: 36px;
    &-login-form {
      top: -36px;
    }
  }
  .buttons {
    margin-top: $step;
  }
}
