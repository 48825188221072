@import '../../styles/config.scss';

.not-available {
  .logo {
    cursor: initial;
    pointer-events: none;
  }
  .account,
  .open-help,
  .widthraw {
    opacity: 0;
  }
  .footer {
    span {
      opacity: 0;
    }
  }
  h1 {
    color: $white;
  }
  .social {
    margin-top: $step * 3/2;
    .icon {
      width: $step + $step/4;
      height: $step + $step/4;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      margin-left: $step;
      cursor: pointer;
      text-indent: -99999px;

      &:first-child {
        margin-left: 0;
      }
      &:hover {
        opacity: 0.8;
      }
    }
    .tg {
      background-image: url(img/TM.svg);
    }
    .fb {
      background-image: url(img/FB.svg);
    }
    .tw {
      background-image: url(img/TW.svg);
    }
  }
}
