$step: 24px;
$step-mobile: 24px;
$column-gap: 48px;
$max-container-width: 1280px;
$font-family-auto: 'TTNorms', 'Noto Sans SC', sans-serif;
$font-family-medium: 'TTNorms-Medium', 'Noto Sans SC', sans-serif;
$font-family-bold: 'TTNorms-Bold', 'Noto Sans SC', sans-serif;
$font-family-light: 'TTNorms-Light', 'Noto Sans SC', sans-serif;

$font-size-01: 10px;
$font-size-0: 12px;
$font-size-1: 16px;
$font-size-2: 24px;
$font-size-3: 32px;
$font-size-4: 48px;
$font-size-5: 72px;
$font-size-6: 108px;
$font-size-7: 144px;
// depricated
$font-size-0-mobile: 9px;
$font-size-1-mobile: 12px;
$font-size-2-mobile: 18px;
$font-size-5-mobile: 36px;

// colors
$blue: #012a5b;
$orange: #e08b09;
$black: #111111;
$black-opacity-50: rgba(11, 11, 11, 0.5);
$red: #bc2139;
$darkred: #bc2139;
$lightblue: #1356a8;
$darkblue: #002a5b;
$grey: #aaaaaa;
$grey-dark: #444444;
$bg-grey: #555555;
$lightgrey: #dddddd;
$light-grey-bg: #eeeeee;
$white: #fafafa;
$sky-blue: #139eef;
$green: #32bc42;
$accent: #ff303f;

// animations
$speed: 0.3s;

// Extra small devices (portrait phones, less than 576px)
$xs: 576px;
// Small devices (landscape phones, less than 768px)
$sm: 768px;
// Medium devices (tablets, less than 992px)
$md: 992px;
// Large devices (desktops, less than 1200px)
$lg: 1200px;

$z-index-header: 200;
$z-index-popup: 150;
