@import '../../../../styles/config.scss';

.hamburger {
  position: relative;
  width: $step * 2;
  height: $step * 2;
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;

  &:not(:hover),
  &:not(:hover) .hamburger-line {
    animation-duration: 0s !important;
  }

  &.disabled {
    .hamburger-line:first-child {
      animation: topLineOff 0.1s linear forwards;
    }

    .hamburger-line:last-child {
      animation: bottomLineOff 0.1s linear forwards;
    }
  }

  &.enabled {
    .hamburger-line:first-child {
      animation: topLineOn 0.1s linear forwards;
    }

    .hamburger-line:last-child {
      animation: bottomLineOn 0.1s linear forwards;
    }
  }

  @keyframes topLineOn {
    0% {
      transform: translate(-50%, -4px);
    }

    50% {
      transform: translate(-50%, 0);
    }

    100% {
      transform: translate(-50%, 0) rotate(45deg);
    }
  }

  @keyframes bottomLineOn {
    0% {
      transform: translate(-50%, 4px);
    }

    50% {
      transform: translate(-50%, 0);
    }

    100% {
      transform: translate(-50%, 0) rotate(-45deg);
    }
  }

  @keyframes topLineOff {
    0% {
      transform: translate(-50%, 0) rotate(45deg);
    }

    50% {
      transform: translate(-50%, 0);
    }

    100% {
      transform: translate(-50%, -4px);
    }
  }

  @keyframes bottomLineOff {
    0% {
      transform: translate(-50%, 0) rotate(-45deg);
    }

    50% {
      transform: translate(-50%, 0);
    }

    100% {
      transform: translate(-50%, 4px);
    }
  }

  &-line {
    display: block;
    background: $white;
    width: $step;
    height: $step / 12;
    background: #fafafa;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    will-change: transform;

    &:first-child {
      transform: translate(-50%, 4px);
    }

    &:last-child {
      transform: translate(-50%, -4px);
    }
  }
}
