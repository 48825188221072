.scroller {
  overflow: hidden;
  position: relative;
  background-color: black;

  &-disabled {
    overflow: auto;
  }

  &-grabbed {
    user-select: none;
  }

  &-middle {
    position: relative;
    overflow: auto;
    width: 100%;
    height: 100%;
  }

  &-inner {
    overflow: hidden;

    &-disabled {
      overflow: auto;
    }
  }

  &-scroll {
    position: absolute;
    right: 6px;
    bottom: 6px;

    &-horizontal {
      left: 6px;
    }

    &-vertical {
      top: 6px;
    }
  }

  &-drag {
    cursor: pointer;
    background: #444444;
    position: absolute;
    border-radius: 10000px;
    z-index: 1;

    &-horizontal {
      height: 100%;
    }

    &-vertical {
      width: 100%;
    }
  }
}
