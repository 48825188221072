.checkbox {
  margin-bottom: 24px;
  font-size: 18px;
}

.termsDescription {
  margin-bottom: 12px;
  width: 100%;
}

.termsErrorBottom {
  margin-top: 20px;
  font-weight: 400;
  color: var(--red);
  float: right;
}
