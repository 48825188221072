a,
.link {
  font-family: $font-family-medium;
  font-weight: 500;
  color: $lightblue;
  cursor: pointer;
  text-decoration: none;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  outline: none;
  &:hover {
    color: $blue;
  }
  &[disabled] {
    pointer-events: none;
    color: $grey;
  }
}
.link-white {
  color: $white;
  display: inline-block;
  &:hover {
    color: $white;
    border-bottom: 2px solid $white;
  }
}
.link-gray {
  color: $grey;
  &:hover {
    color: $lightblue;
  }
}
.buttons {
  .link {
    font-size: $font-size-1;

    //@media screen and (max-width: $xs) {
    //  & {
    //    font-size: $font-size-0;
    //  }
    //}
  }
  .link + .btn {
    margin-left: $step;
  }
  .btn-small + .link {
    font-size: $font-size-0;
  }
}
