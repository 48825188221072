.font-light {
  font-weight: 300;
  font-family: $font-family-light;
}
.font-regular {
  font-weight: 400;
  font-family: $font-family-auto;
}
.font-medium {
  font-weight: 500;
  font-family: $font-family-medium;
}
.font-bold {
  font-weight: 700;
  font-family: $font-family-bold;
}
.font-black {
  font-weight: 900;
  font-family: $font-family-bold;
}

/* type = line-height */
.heading-0-type-1 {
  margin: 0;
  line-height: $font-size-7;
  font-size: $font-size-7;
  padding-top: 19px;
  padding-bottom: 5px;
}

.heading-1-type-1 {
  margin: 0;
  line-height: $font-size-6;
  font-size: $font-size-6;
  padding-top: 2px;
  padding-bottom: 10px;
}

.heading-1-type-2 {
  margin: 0;
  line-height: $font-size-7;
  font-size: $font-size-6;
  padding-top: 8px;
  padding-bottom: 16px;
}

.heading-2-type-1 {
  margin: 0;
  line-height: $font-size-5;
  font-size: $font-size-5;
  padding-top: 10px;
  padding-bottom: 14px;
}

.heading-2-type-2 {
  margin: 0;
  line-height: $font-size-6;
  font-size: $font-size-5;
  padding-top: 16px;
  padding-bottom: 20px;
}

.heading-3-type-1 {
  margin: 0;
  line-height: $font-size-4;
  font-size: $font-size-4;
  padding-top: 7px;
  padding-bottom: 17px;
}

.text-1-type-1 {
  margin: 0;
  line-height: 36px;
  font-size: 36px;
  padding-top: 17px;
  padding-bottom: 19px;
}

.text-1-type-2 {
  margin: 0;
  line-height: $step * 2;
  font-size: 36px;
  padding-top: 10px;
  padding-bottom: 14px;
}

.text-2-type-1 {
  margin: 0;
  line-height: $font-size-3;
  font-size: $font-size-3;
  padding-top: 20px;
  padding-bottom: 20px;
}

.text-2-type-2 {
  margin: 0;
  line-height: 36px;
  font-size: $font-size-3;
  padding-top: 17px;
  padding-bottom: 19px;
}

.text-3-type-1 {
  margin: 0;
  line-height: $font-size-2;
  font-size: $font-size-2;
  padding-top: 3px;
  padding-bottom: 21px;
}

.text-3-type-2 {
  margin: 0;
  line-height: 32px;
  font-size: $font-size-2;
  padding-top: 23px;
  padding-bottom: 17px;
}

.text-3-type-3 {
  margin: 0;
  line-height: 36px;
  font-size: $font-size-2;
  padding-top: 20px;
  padding-bottom: 16px;
}

.text-4-type-1 {
  margin: 0;
  line-height: $font-size-1;
  font-size: $font-size-1;
  padding-top: 11px;
  padding-bottom: 21px;
}

.text-4-type-2 {
  margin: 0;
  line-height: $step;
  font-size: $font-size-1;
  padding-top: 7px;
  padding-bottom: 17px;
}

.text-4-type-3 {
  margin: 0;
  line-height: $step * 2;
  font-size: $font-size-1;
  padding-top: 18px;
  padding-bottom: 6px;
}

.text-5-type-1 {
  margin: 0;
  line-height: $font-size-0;
  font-size: $font-size-0;
  padding-top: 14px;
  padding-bottom: 22px;
}

.text-5-type-2 {
  margin: 0;
  line-height: $step/4 * 3;
  font-size: $font-size-0;
  padding-top: 11px;
  padding-bottom: 19px;
}

.text-5-type-3 {
  margin: 0;
  line-height: $step/3 * 2;
  font-size: $font-size-0;
  padding-top: 11px;
  padding-bottom: 21px;
}

.text-5-type-4 {
  margin: 0;
  line-height: $step;
  font-size: $font-size-0;
  padding-top: 7px;
  padding-bottom: 17px;
}

.text-5-type-5 {
  margin: 0;
  line-height: $step * 2;
  font-size: $font-size-0;
  padding-top: 19px;
  padding-bottom: 5px;
}

.text-6-type-1 {
  margin: 0;
  line-height: $font-size-01;
  font-size: $font-size-01;
  padding-top: 15px;
  padding-bottom: 23px;
}

.text-6-type-2 {
  margin: 0;
  line-height: $step/3 * 2;
  font-size: $font-size-01;
  padding-top: 11px;
  padding-bottom: 21px;
}

.text-6-type-3 {
  margin: 0;
  line-height: 14px;
  font-size: $font-size-01;
  padding-top: 12px;
  padding-bottom: 22px;
}

.text-6-type-4 {
  margin: 0;
  line-height: $step/4 * 3;
  font-size: $font-size-01;
  padding-top: 10px;
  padding-bottom: 20px;
}

.text-6-type-5 {
  margin: 0;
  line-height: $step/2;
  font-size: $font-size-01;
  padding-top: 13px;
  padding-bottom: 23px;
}
