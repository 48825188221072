.image {
  max-width: 100%;
  max-height: calc(100vh - 48px);
  width: auto !important;
}

.image1 {
  max-width: 100%;
  max-height: 100%;
  width: auto !important;
  position: absolute;
  top: 0;
  left: 0;
}

.document {
  overflow: auto;
  max-height: calc(100vh - 68px);
  height: 100%;
}
.docPage {
  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.root {
  position: relative;
}
