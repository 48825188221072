@import '../../styles/config.scss';

$svgBottomHeight: 15px; // высота текста под картинкой
$svgBottomOffset: $svgBottomHeight + $step;

$photo-max-height-with-bottoms: calc(
  100vh - #{$step * 4} - 24px - #{$svgBottomOffset}
);
$photo-max-height: calc(100vh - #{$step * 4} - 24px);
$photo-max-height-sm: calc(100vh / 2);
$photo-max-height-xs: calc(100vh / 4);
$rainbow: #ff303f #139eef #f99018 #12edba #ff44c0 #212fff #fccc20 #bc19fc
  #e04a02 #14e814 #fbab84 #02c5e0 #b5000d #5a135b #e56b6f #2ec4b6 #ff6392
  #3a86ff #d77a61 #affc41 #f694c1 #6d9dc5 #f6bd60 #e4c1f9 #f3722c #60d394
  #d5b9b2 #58aeaa #db3a34 #7678ed #fec5bb #99c224 #ec0868 #a0c4ff #b08968
  #31572c #dcabdf #416799 #f2a65a #b392ac #e9d985 #40916c #c1b098 #bee3db
  #ca2e55 #480ca8 #e8998d #005f73 #e98a15 #5e548e;

.popup-task-fulldescription {
  .popup-content {
    margin-left: auto;
    margin-right: auto;
  }
}

.svg-bottom {
  $offset: 12px;

  > p {
    width: 100%;
    margin-top: $offset;
  }

  .buttons {
    margin-top: 0;
    margin-right: $offset;
  }

  .btn-small {
    margin-right: $offset;
    color: #111111;
    background-color: #dddddd;

    &:hover {
      background: #aaaaaa;
      color: white;
    }

    &:active,
    &.active {
      color: white;
      background: #111111;
    }
  }

  .link {
    font-size: $offset;
    color: #aaaaaa;
    margin-right: $offset;

    &:hover {
      color: $lightblue;
    }

    &.toggle {
      margin-left: -6px;
      border-radius: 8px;
      padding: 0 4px;
      border: 2px solid transparent;

      &.active {
        border-color: $lightblue;
        color: $lightblue;
      }
    }
  }
}

.label-page {
  .tooltip {
    font-style: normal;
  }

  div.container {
    width: 100%;
    height: 100%;
    padding: 0;
    padding-top: $step * 2;
  }

  .core-wrapper {
    position: relative;
  }

  .container-inner {
    height: calc(100vh - #{$step} * 4);
  }

  .bttn {
    &-caption {
      font-size: inherit;
      padding: 8px 20px 12px;
    }

    @for $i from 1 through length($rainbow) {
      $c: nth($rainbow, $i);

      &.rainbow:nth-child(#{$i}) {
        color: $black;
        background-color: $lightgrey;
        border-color: $lightgrey;

        &.exists {
          background-color: rgba($c, 0.5);
          color: $white;
          border-color: transparent;
        }

        &:hover,
        &.pressed {
          color: $white;
          background-color: $c;
          border-color: $c;
        }
      }
    }
  }

  .footer {
    height: 0;
  }

  .annotation {
    height: 100%;
    flex-wrap: unset;

    @media screen and (max-width: $xs) {
      flex-wrap: wrap;
    }

    &.annotation-fullscreen {
      .core-wrapper {
        position: fixed;
      }

      .image {
        .image-slider {
          &-item {
            max-height: unset;
            max-width: unset;
            position: absolute;
            top: 50%;
            left: 50%;
          }

          &-nav {
            position: fixed;
            z-index: 10000;
            left: 0;
            display: flex;
            height: auto;
            flex-wrap: wrap;
            align-items: center;
            justify-content: left;
            width: $step * 1.5;
            margin: 0;
            margin-left: $step * 1.5;

            &-item {
              width: $step * 2 + $step / 2;
              height: $step * 2 + $step / 2;
              cursor: pointer;

              span {
                width: $step * 2;
                height: $step * 2;
                margin: $step / 4;
                text-align: center;
                line-height: $step * 2;
                background: $grey-dark;
              }

              &:hover,
              &.hover {
                span {
                  color: $black;
                  background: $grey;
                }
              }

              &.active {
                span {
                  color: $black;
                }

                &:hover {
                  span {
                    color: $black;
                  }
                }
              }
            }
          }
        }
      }
    }

    .image-wrapper {
      padding-left: calc(
        (100vw - (6 * (100vw - 96px - 5 * 48px) / 6 + 48px * 5)) / 2
      );

      @media screen and (min-width: 1280px) {
        padding-left: calc((100vw - 1184px) / 2);
      }

      @media screen and (max-width: $sm) {
        padding: 0;
        width: 100%;
        height: auto;
        margin-top: $step;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .image {
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      align-self: center;

      .image-slider {
        &-item {
          max-height: calc(100vh - 96px - 24px - 39px - 24px);
          max-width: 100%;
          display: none;
          position: relative;

          &.active {
            display: block;
          }

          &.hover {
            display: block !important;

            ~ .active {
              display: none !important;
            }
          }
        }

        &-nav {
          margin-top: $step;

          &-item {
            width: $step * 1.5;
            height: $step * 1.5;
            background: none;
            border: none;
            display: inline-block;
            padding: 0;
            line-height: $step;
            color: $grey;
            font-size: 12px;
            text-align: center;
            cursor: pointer;

            span {
              display: block;
              width: $step;
              height: $step;
              margin: 0 auto;
              border-radius: 50%;
            }

            &:hover,
            &.hover {
              span {
                color: $lightblue;
              }
            }

            &.active {
              span {
                background: $grey;
                color: $white;
              }

              &:hover {
                span {
                  color: $white;
                }
              }
            }
          }
        }
      }

      .text-value {
        display: flex;
        font-size: $step;
        line-height: $step * 1.5;
        padding: 0 $step * 4;
        width: 100%;
        height: 100%;
        align-items: center;
        margin-top: $step * 2;
        margin-bottom: $step * 4;
        background: #eee;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
        overflow-y: auto;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
          Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
        font-weight: 400;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        span {
          display: block;
          max-height: 100%;
          width: 100%;
          padding: $step * 4 0;
          margin-top: auto;
          margin-bottom: auto;
        }

        strong {
          font-weight: 700;
        }
      }

      .image-value {
        position: relative;
        max-width: 100%;
        max-height: $photo-max-height;
        margin: auto;
        display: block;

        &.ocr {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &-with-bottoms {
          max-height: $photo-max-height-with-bottoms;
        }

        @media screen and (max-width: $sm) {
          max-height: $photo-max-height-sm;
        }

        @media screen and (max-width: $xs) {
          max-height: $photo-max-height-xs;
        }

        > img {
          max-height: calc(100vh - 96px - 24px - 39px);
          max-width: 100%;
          height: auto;
        }
      }
    }

    .actions {
      display: flex;
      position: relative;
      align-items: center;
      margin-top: auto;
      margin-bottom: auto;

      @media screen and (max-width: $sm) {
        margin-top: $step;
      }

      &-wrapper {
        padding-left: $step * 2;
        width: 100%;

        @media screen and (max-width: $sm) {
          padding: 0 $step;
        }
      }

      &-outer {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        overflow-y: auto;
        overflow-x: hidden;
      }

      .btn {
        margin: 0 $step/2 $step/2 0;
        color: $white;

        &:hover,
        &.active {
          background-color: $lightblue;
        }

        &-round {
          min-width: $step * 2;
          height: $step * 2;
        }
      }

      &-inner {
        flex: 1;
        position: relative;
        margin-right: 20px;
        padding: $step 0;
        //max-height: 50%; // TODO: HOTFIX! Avoid huge empty space at the bottom. Decide what to do with this.

        @media screen and (max-width: $sm) {
          margin-top: 0;
          top: auto;
        }

        .buttons.marks {
          margin-bottom: -$step / 2;
        }
      }

      .test {
        color: $grey;
        letter-spacing: 1px;
      }

      .caption {
        white-space: pre-line;
        letter-spacing: -0.2px;
        line-height: $step * 2;
        padding-top: 0;
        padding-bottom: 0;
        font-size: 40px;

        .bttn {
          margin: 0;
        }
      }

      .description {
        color: $black;
        font-weight: normal;
        line-height: $step;
        margin-bottom: 42px;
        padding-top: 18px;
        padding-bottom: $step / 6;

        img {
          max-width: 100%;
        }

        strong,
        b {
          font-weight: 500;
        }

        em,
        i {
          font-style: italic;
        }

        .tooltip {
          font-style: normal;
        }

        > p {
          display: inline;

          > p {
            display: inline;
          }
        }

        &-icon-full {
          width: 18px;
          height: 18px;
        }
      }

      .buttons-outer {
        .bttn {
          margin-bottom: $step / 2;
          margin-right: $step / 2;
        }
      }

      .buttons {
        .btn-border {
          margin: 0 $step/2 $step/2 0;

          &.active {
            border-color: $lightblue;
            color: $lightblue;
            background: transparent;
          }

          &.rainbow {
            padding: 1px 18px;
          }
        }

        @media screen and (max-width: $xs) {
          margin-top: $step;
        }

        &:after {
          content: '';
          display: table;
          clear: both;
        }
      }

      .tabs {
        .tab-btn {
          border-radius: 0;
          margin-left: 0;
          margin-right: 0;
          margin-top: $step/2;
          padding: 1px 10px;

          &:first-child {
            border-radius: 73px 0 0 73px;
            padding-left: 15px;
          }

          &:last-child {
            padding-right: 15px;
            border-radius: 0 73px 73px 0;
          }

          &.pressed {
            background-color: $lightblue;
          }
        }
      }

      .second-actions {
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        line-height: 12px;
      }

      .skip,
      .prev {
        cursor: pointer;
        display: inline-block;
        color: $grey;

        &:hover {
          color: $lightblue;
        }
      }
    }
  }
}

.task-guide {
  display: flex;
  justify-content: center;
  align-items: center;

  &-inner {
    width: calc(4 * (100% - 5 * 48px) / 6 + 48px * 3);
    max-width: 806px;

    @media screen and (max-width: $sm) {
      width: 100%;
      max-width: 100%;
    }
  }

  &-heading {
    padding-top: 10px;
    font-size: $font-size-5;
    line-height: $font-size-5;
    margin-bottom: $step * 2 - 10px;
    font-family: $font-family-bold;

    @media screen and (max-width: $sm) {
      font-size: $font-size-4;
      line-height: $font-size-4;
    }
  }

  .screen {
    margin-bottom: 29px;

    img {
      max-width: 100%;
    }
  }

  .description {
    padding-right: 213px;

    @media screen and (max-width: $xs) {
      padding-right: 0;
    }
  }

  .buttons {
    padding-right: 213px;
    margin-top: 18px;

    @media screen and (max-width: $xs) {
      padding-right: 0;
    }

    .btn-border-white {
      &:hover {
        background: $white;
        color: $black;
        border-color: $white;
      }
    }
  }
}

.body-state-guide-open .popup-wrapper {
  background: $black;
}

@keyframes process {
  0% {
    background-color: $red;
  }

  100% {
    background-color: $white;
  }
}

.svg_container {
  padding: 0;
  margin: 0;
  max-width: 100%;
  max-height: 100%;
  position: relative;
  background: $white;
  margin-bottom: $step;
  user-select: none;

  .svg-area {
    top: 50%;
    left: 50%;
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
  }

  .svg_container__loading {
    animation-name: process;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
  }

  text {
    user-select: none;
    cursor: default;
  }

  .current .rect {
    cursor: move;
  }

  .current .rect-label {
    position: absolute;
    left: 0;
    top: 0;
  }

  .n-resize {
    cursor: n-resize;
  }

  .w-resize {
    cursor: w-resize;
  }

  .e-resize {
    cursor: e-resize;
  }

  .s-resize {
    cursor: s-resize;
  }

  .ne-resize {
    cursor: ne-resize;
  }

  .nw-resize {
    cursor: nw-resize;
  }

  .se-resize {
    cursor: se-resize;
  }

  .sw-resize {
    cursor: sw-resize;
  }

  .rect_circle_resize {
    fill: $lightblue;
  }

  .transparent {
    fill: transparent;
  }

  .rect_circle_delete {
    cursor: pointer;

    &.transparent {
      fill: transparent;
    }
  }

  .point {
    cursor: move;
    fill: $lightblue;

    &.close {
      cursor: alias;
    }
  }

  .path_delete {
    cursor: pointer;

    &.transparent {
      fill: transparent;
    }
  }
}

.editor-buttons {
  position: absolute;
  left: 0;
  bottom: -53px;
  opacity: 1;
  transition: opacity 0.3s;

  &-hidden {
    opacity: 0;
  }

  .editor-button {
    cursor: pointer;
    margin-right: $step;
    color: $grey;

    &:hover {
      color: $lightblue;
    }
  }
}

.task-spinner {
  width: 100%;
  height: 100%;
}

.popup-task-guide {
  background: transparent;
  color: $white;
  width: 100%;
}

.annotation-fullscreen {
  .core-scroller {
    margin-bottom: 0;
  }

  .scroller-scroll {
    right: 8px;
    bottom: 8px;
  }
}

.core-scroller {
  .svg_container {
    margin-bottom: 0;
  }
}

.descriptionpopup {
  &-header {
    padding-top: 5px;
    font-size: 72px;
    letter-spacing: -0.4px;
    line-height: 1;
    margin-bottom: $step * 2;

    .bttn {
      $c: nth($rainbow, 1);
      background-color: $c;
      font-size: inherit;
      color: $white;
      margin: 0;
      cursor: default;
    }
  }

  &-subhead {
    font-size: 48px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: $step * 2.5;
  }

  &-main {
    padding-top: 21px;
    font-size: 24px;
    line-height: 36px;
    font-weight: normal;
    margin-bottom: $step * 3;

    strong,
    b {
      font-weight: 500;
    }

    em,
    i {
      font-style: italic;
    }
  }

  &-labels {
    .labels-heading {
      margin-bottom: $step * 2;
    }
  }

  &-buttonslist {
    .bttn {
      background-color: $black;
      border-color: $black;
    }

    .buttonslist {
      &-labelslist {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
      }

      &-label {
        margin-bottom: $step * 2;
        margin-left: 0 !important;

        &:nth-child(2n) {
          margin-left: $step * 2;
        }

        &-description {
          display: block;
          margin-top: $step;
          font-size: 16px;
          line-height: $step;
          font-weight: 400;
        }
      }

      &-group {
        font-size: 16px;
        line-height: $step;
        font-weight: 500;
        margin-bottom: $step;
      }
    }
  }

  &-extra {
    counter-reset: section;
    padding-top: 6px;

    h4 {
      margin: 0;
      line-height: 32px;
      font-size: 24px;
      padding-top: 23px;
      padding-bottom: 17px;
      font-family: TTNorms-Medium, Noto Sans SC, sans-serif;
      height: 48px;
      line-height: 46px;
      padding: 1px 20px;
      display: inline-block;
      margin-bottom: 19px;
      margin-top: 53px;
      margin-left: -15px;
      font-size: 16px;
      background-color: #111111;
      border-radius: 73px;
      border: none;
      color: white;
      box-sizing: border-box;
      cursor: default;
      outline: none;

      &:before {
        counter-increment: section;
        content: counter(section) '. ';
      }
    }

    p {
      margin-bottom: $step;
    }

    li {
      position: relative;
      padding-left: $step;
      margin-bottom: $step;

      &:before {
        content: '';
        display: block;
        width: 12px;
        height: 12px;
        background: #111111;
        border-radius: 50%;
        position: absolute;
        left: 0;
        margin-top: 6px;
      }
    }
  }
}

.fulldescrtiption-link {
  margin-bottom: -4px;
  display: block;
  width: 18px;
  height: 18px;
  color: $grey;
  float: left;

  &:hover {
    color: $lightblue;
  }

  + .tooltip-content {
    // 26px - height of svg + default tooltip top offset (8px). buggy beacause float

    transform: translate(-50%, 26px);
  }
}

.svg-button-rotate {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  z-index: 1;
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  color: #ddd;
  user-select: none;
  cursor: pointer;

  &:hover {
    color: #fafafa;
  }

  svg {
    width: 24px;
    height: 24px;
    display: block;
  }
}

.task-container {
  background-color: #555555;
  height: calc(100vh - 48px);
}
