@import '../../../../../../../styles/config.scss';

@mixin border($border-size, $border-color) {
  position: relative;
  box-shadow: 0 0 0 $border-size $border-color;
  z-index: 10;
}

.tableContainer {
  height: 100%;
  display: flex;
  align-items: baseline;
  overflow: scroll;
}

.table {
  border-spacing: 1px;
  overflow: scroll;
  width: 100%;
  max-height: 100%;
}

.tableData {
  box-shadow: 0 0 0 1px $lightgrey;
  background: white;
  min-width: max-content;
  height: 32px;
  padding: 8px 8px 10px;

  &.isLowConfidence:not(.focusedTd, .editedTd) {
    @include border(1px, $accent);
    z-index: 0;
  }

  &.editedTd {
    @include border(1px, $green);
  }

  &.editedTd.focusedTd {
    @include border(1.5px, $green);
  }

  &:hover:not(.focusedTd) {
    @include border(1px, $sky-blue);
    z-index: 100;
  }
}

.focusedTd {
  @include border(1.5px, $sky-blue);
}

.cellInput {
  display: block;
  color: $bg-grey;
  border: none;
  height: 100%;
  font-weight: 500;
  min-width: 100%;
}

.widthSetter {
  display: block;
  width: max-content;
  visibility: hidden;
  height: 1px;
}