@import '../../static/styles/config';

.grid {
  display: none;
  opacity: 0.75;
  background-color: transparent;
  background-image: linear-gradient(to bottom, $red 1px, transparent 1px);
  width: 100%;
  position: fixed;
  top: -1px;
  left: 0;
  bottom: -1px;
  z-index: 500;
  background-size: 100% $step;
  &.state-active {
    display: block;
  }
  .grid-col {
    background-color: rgba(24, 80, 147, 0.25);
    height: 100vh;
  }
}
