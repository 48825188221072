.checkbox-label,
.radio-label {
  position: relative;
  padding-left: $step;
  display: inline-block;
}
.checkbox-wrapper {
  position: absolute;
  left: 0;
  top: -3px;

  .input-image {
    cursor: pointer;
    content: '';
    width: 12px;
    height: 12px;
    position: absolute;
    left: 2px;
    top: 2px;
    box-sizing: border-box;
    border: 2px solid $lightgrey;
    &::after,
    &::before {
      opacity: 0;
    }
    &::after {
      content: '';
      position: absolute;
      left: -5px;
      top: 0;
      width: 8px;
      height: 8px;
      border-right: 2px solid $white;
    }
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: -5px;
      width: 8px;
      height: 8px;
      border-bottom: 2px solid $white;
    }
  }
  input[type='checkbox'] {
    display: none;
  }
  input[type='checkbox']:checked + .input-image {
    background: $lightblue;
    border: 2px solid $lightblue;
    &::after,
    &::before {
      opacity: 1;
    }
  }
}
