@import '../../styles/config.scss';
.container-wrapper,
.container {
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
  display: block;
}
.enter-page--container {
  height: 100%;
  position: relative;
  min-height: 100vh;
  display: flex;
}

.enter-page--left {
  display: flex;
  height: 100%;
  min-height: 100vh;
  flex-grow: 1;
  max-width: 50%;
}
.enter-page--left-image {
  width: 100%;
  object-fit: cover;
}

.enter-page--right {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $lightblue;
}
.enter-page--form-container {
  width: 50%;
}
