@import '../../../styles/config.scss';

.inputField {
  height: 40px !important;
  border-radius: 4px;
  background-color: $light-grey-bg !important;
}

.danger {
  border: 2px solid $accent !important;
}

.inputField,
.danger {
  &:focus {
    border: 2px solid $sky-blue !important;
  }
}

.inputLabel {
  margin-bottom: 8px;
}
.inputBlock {
  margin-bottom: 18px;
}
