@import '../../../../styles/config.scss';
$rainbow: #ff303f #139eef #f99018 #12edba #ff44c0 #212fff #fccc20 #bc19fc
  #14e814 #e04a02 #02c5e0 #fbab84 #5a135b #b6000d #99c224 #ff303f #139eef
  #f99018 #12edba #ff44c0 #212fff #fccc20 #bc19fc #14e814 #e04a02 #02c5e0
  #fbab84 #5a135b #b6000d #99c224 #ff303f #139eef #f99018 #12edba #ff44c0
  #212fff #fccc20 #bc19fc #14e814 #e04a02 #02c5e0 #fbab84 #5a135b #b6000d
  #99c224;

.image-textcategorization.image {
  position: relative;
  max-width: 100%;
  max-height: 100%;

  .text {
    &-value {
      margin-bottom: $step * 2 !important;
      padding: 0 !important;

      &-main {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
      }

      &-main {
        &::selection {
          background: transparent;
        }

        @for $i from 1 through length($rainbow) {
          $c: nth($rainbow, $i);

          &__selection-#{$i} {
            &::selection {
              background: rgba($c, 0.5);
            }
          }
        }
      }

      @for $i from 1 through length($rainbow) {
        $c: nth($rainbow, $i);

        &-close__idx-#{$i} {
          color: $c;
        }

        @each $c in $rainbow {
          $i: index($rainbow, $c);

          &__selection-#{$i} {
            &::selection {
              background: rgba($c, 0.5);
            }
          }
        }
      }

      @each $c in $rainbow {
        $i: index($rainbow, $c);

        &-close__idx-#{$i} {
          color: $c;
        }

        &-range__idx-#{$i} {
          background: $c;
          opacity: 0.5;
        }
      }

      &-close {
        border: none;
        width: 18px;
        height: 18px;
        padding: 0;
        border-radius: 50%;
        background: transparent;
        position: absolute;
        z-index: 20;
        transform: translate(-10px, -5px);
        cursor: pointer;

        svg {
          width: 18px;
          height: 18px;
        }
      }

      &-range {
        z-index: 1;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color: transparent;

        &__closefaker {
          position: absolute;
          height: 36px;
          width: 0px;
        }
      }

      &-wrapper {
        position: relative;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        white-space: pre-line;

        span {
          display: inline !important;
          max-height: inherit !important;
          padding: 0 !important;
        }
      }

      p {
        display: block;
        max-height: 100%;
        width: 100%;
        padding: 0 (2 * $step);
        box-sizing: border-box;
        margin-top: auto;
        margin-bottom: auto;
        background: none;
      }
    }
  }
}

.buttons.marks.grouped {
  margin-top: $step * 1.5;

  .buttons-title {
    padding-top: 0;
    padding-bottom: $step / 2;
  }

  @for $i from 1 through length($rainbow) {
    $c: nth($rainbow, $i);

    &:nth-of-type(#{$i}) {
      &.grouped__active {
        color: $c;
      }

      &:hover,
      &.pressed {
        color: $white;
        background-color: $c;
        border-color: $c;
      }
    }
  }
}
