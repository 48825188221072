@import '../../styles/config.scss';

.table {
  width: auto;
  display: flex;
  flex-direction: column;

  &__nodata {
    color: $grey;
  }

  &-wide {
    width: 100%;
  }

  &-row {
    display: flex;
    flex-direction: row;
    width: 100%;

    .col-1 {
      @media screen and (max-width: $sm) {
        width: 25%;
        margin-left: $step / 2;
        font-size: 12px;

        &:first-of-type {
          margin-left: 0;
        }
      }
    }

    &-header {
      margin-bottom: -$step/4;
    }

    &-body {
      margin-left: -$step;
      margin-right: -$step;
      padding-left: $step;
      padding-right: $step;

      > .col-1 {
        padding-bottom: $step/2;
        padding-top: $step/2;
      }

      &:hover {
        background: $lightgrey;
      }
    }
  }

  .color {
    &-status-completed,
    &-grey {
      color: $grey;
    }

    &-status-failed,
    &-red {
      color: $red;
    }
  }
}
