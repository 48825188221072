@import '../../styles/config.scss';

.profile-page {
  .row-last {
    margin-top: $step;
    justify-content: flex-end;
  }

  .account {
    @media screen and (max-width: $xs) {
      margin-top: $step;
    }
    .avatar {
      height: 156px;
      width: 156px;
      margin-right: 2 * $step;
      display: block;
      float: left;
      outline: none;
      @media screen and (max-width: $xs) {
        float: none;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .info {
      margin-top: $step;
      float: left;
      width: calc(100% - 165px - 2 *#{$step});
      @media screen and (max-width: $xs) {
        float: none;
        width: auto;
      }
      form {
        .field-error {
          margin-bottom: $step/2;
          color: $grey;
          font-size: $font-size-01;
          padding: $step 0 0 $step/2;
        }
        .field {
          margin: 0;
        }
        .editing {
          .eth-name {
            @media screen and (min-width: $xs) {
              display: none;
            }
          }
        }
        .label {
          display: block;
          width: 100%;
          @media screen and (max-width: $xs) {
            height: auto;
            margin-top: $step-mobile;
          }
          .label-name {
            padding-right: $step/4;
            outline: none;
          }
          div {
            display: inline-block;
            @media screen and (max-width: $xs) {
              display: block;
              height: $step;
            }
          }
          input {
            outline: none;
            background-color: $lightgrey;
            border: none;
            width: 100%;
            padding: 0;
            height: $step;
            line-height: $step;
            @media screen and (max-width: $xs) {
              height: $step-mobile;
              line-height: $step-mobile;
              font-size: $font-size-0;
              padding-left: 0;
              display: flex;
            }
            &:-webkit-autofill {
              background-color: $lightgrey !important;
              -webkit-box-shadow: inset 0 0 0 50px $lightgrey;
              -webkit-text-fill-color: $black;
            }
            &[disabled] {
              color: $black;
              background-color: transparent;
              border: none;
            }
          }
          &.editing {
            // margin-bottom: $step/2;
            div {
              display: block;
              width: 100%;
            }
            input {
              width: 100%;
            }
            .field-error {
              @media screen and (max-width: $xs) {
                line-height: $step/2;
              }
            }
          }
        }
        .edit {
          display: inline-block;
          margin-top: $step;
          line-height: 17px;
          @media screen and (max-width: $xs) {
            line-height: $step;
            margin-top: $step * 2;
          }
        }
      }
    }
    &:after {
      content: '';
      display: table;
      clear: both;
    }
    .buttons {
      @media screen and (max-width: $xs) {
        margin-top: $step * 2;
      }
    }
  }
  .buttons {
    margin-top: $step;
    display: flex;
  }
  .extra {
    margin-top: $step * 5/2;
    .line-2 {
      min-width: $step * 6.875;
      @media screen and (min-width: $xs) {
        margin-top: $step/4;
      }
    }
    .line-3 {
      min-width: $step * 6.875;
    }
  }

  .buttons-signout {
    @media screen and (max-width: $xs) {
      margin-top: -24px;
    }
    .link-signout {
      font-size: $font-size-0;
      color: $grey;
      display: block;
      min-width: 60px;
      &:hover {
        color: $darkred;
      }
    }
    .btn:last-child {
      margin-right: 0;
    }
  }

  .telegram-link {
    color: $black;
    &:hover {
      color: $lightblue;
    }
    .icon-success {
      background-size: 18px 18px;
      width: 18px;
      height: 18px;
      outline: none;
    }
  }
  .switch-lang {
    margin-left: 36px;
    display: flex;
    align-items: center;
    @media screen and (max-width: $xs) {
      margin-left: 0;
    }
    .switch-lang-text {
      white-space: nowrap;
    }

    svg {
      border: 2px solid $lightgrey;
      border-radius: 14px;
      margin-right: 4px;
    }
  }
}
