// @import '~reset-css/_reset';
@import 'static/styles/config';
@import 'static/styles/fonts-connect';
@import 'static/styles/fonts';
@import 'static/styles/effects';
@import 'static/styles/grid';
@import 'static/styles/grid-layer';
@import 'static/styles/forms';
@import 'static/styles/links';
@import 'static/styles/buttons';
@import 'static/styles/icons';
@import 'static/styles/table';
@import 'static/styles/spinner';
@import 'static/styles/popup';
@import 'static/styles/toggle';
@import 'static/styles/scroller';
@import 'static/styles/tooltip';
@import 'static/styles/tip';

html {
  height: 100%;
}
body {
  min-height: 100%;
  max-height: 100%;
  background-color: $white;
  font-family: $font-family-auto;
  font-size: $font-size-0;
  font-weight: 500;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $grey;
    border-radius: 16px;
  }

  &.blue-bg {
    background-color: $blue;
  }
  &.red-bg {
    background-color: $red;
  }
}
h1 {
  font-size: $font-size-5;
  line-height: $step * 2;
  font-weight: bold;
  @media screen and (max-width: $xs) {
    & {
      font-size: $font-size-5-mobile;
      line-height: $step-mobile * 2;
    }
  }
}
h2 {
  font-weight: 500;
  font-size: $font-size-2;
  line-height: $step;
  @media screen and (max-width: $xs) {
    & {
      font-size: $font-size-2-mobile;
      line-height: $step-mobile * 1.5;
    }
  }
}

.text-ligature {
  font-variant-ligatures: normal;
}

.pointer-events-none {
  cursor: default;
  pointer-events: none;
}
.overflow-hidden {
  overflow: hidden;
}
.text-right {
  text-align: right;
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
