@import '../../styles/config.scss';

.root {
  border: 2px solid rgba($sky-blue, 0.25);
  background-color: rgba($sky-blue, 0.25);
  display: block;

  position: relative;
  z-index: 10;
  position: absolute;
}

.active {
  border: 2px solid rgba($accent, 0.6);
  background-color: rgba($accent, 0.25);
}

.edited {
  border: 2px solid rgba($green, 0.25);
  background-color: rgba($green, 0.25);
}
